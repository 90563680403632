export const ANIMATIONS = [
  'fade',
  'fade-left',
  'fade-right',
  'fade-top',
  'fade-bottom',
  'slide-left',
  'slide-right',
  'slide-top',
  'slide-bottom',
  'zoom-in',
  'zoom-out',
  'spin-cw',
  'spin-ccw',
  'rotate-left',
  'rotate-right',
  'rotate-top',
  'rotate-bottom',
] as const;
